export const smoothScrollY = async (target: number, endTime?: number) => {
  const end = { value: false }

  if (endTime) {
    setTimeout(() => (end.value = true), endTime)
  }

  const start = window.scrollY
  for (let i = 0; window.scrollY < start + target; i++) {
    if (end.value) return

    await new Promise((resolve) => {
      setTimeout(resolve, 10)
    })
    window.scrollTo({
      top: window.scrollY + i,
      behavior: 'smooth',
    })
  }
}

/**
 * 対象のIDのDOM要素にスムーススクロール
 * @param id 対象のid
 * @param options.smooth smooth scrollするかどうか
 * @param options.add 足し引きする高さ
 * @param options.endTime スムーススクロール終了時間
 */
export const scrollToId = (
  id: string,
  options: {
    smooth?: boolean
    add?: number
    endTime?: number
  }
) => {
  if (!process.client) return

  const el = document.getElementById(id)

  if (!el) return

  const rect = el.getBoundingClientRect()
  const top = rect.top + window.pageYOffset + (options.add || 0)

  if (options.smooth) {
    window.scrollTo({
      top,
      behavior: 'smooth',
    })
  } else {
    window.scrollTo({ top })
  }
}
